@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400..700;1,400..700&display=swap');

html {
  height: auto;
  background-color: black;
  background: radial-gradient(farthest-corner, #195644, black),
              radial-gradient(ellipse at bottom, black, transparent);
}

body {
  margin: 0;
  min-height: 100%;
  width: 100%;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle;
  align-content: center;
  /* background-color: black;
  background: radial-gradient(farthest-corner, #195644, black),
              radial-gradient(ellipse at bottom, black, transparent); */
}

footer {
  background-color: black;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.50);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: 'Afacad', sans-serif;
  font-style: normal;
}

h2 {
  font-family: 'Afacad', sans-serif;
  font-style: normal;
}

#home-text {
  font-family: 'Afacad', sans-serif;
  color: #D2CAAF;
}

/* @media screen and (min-width: 481px) and (max-width: 768px) {
  .form-all {
    margin: auto;
  };
  .supernova > body {
    margin: auto;
  };
  .jotform-form {
    justify-content: center;
    margin: auto;
  };
  #newsletter-parent > iframe {
    justify-content: center;
  };
} */

.header-scrolled {
  background-color:  #141414;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.7);
}

footer {
  background-color:  #141414;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.7)
}

#footer-info {
  font-family: 'Afacad', sans-serif;
  font-style: normal;
  border-top: solid 1px;
  border-bottom: solid 1px;
  border-color: #B39055;
  border-left: 0px;
  border-right: 0px;
  margin-left: 20px;
  margin-right: 20px;
}

#footer-base {
  font-family: 'Afacad', sans-serif;
  font-style: normal;
}

#footer-socials:hover {
  color: #B39055;
}

#vip-text {
  font-family: 'Afacad', sans-serif;
  color: #D2CAAF;
  text-align: left;
}

#vip-header {
  font-family: 'Afacad', sans-serif;
}

#vip-page-button {
  font-family: 'Afacad', sans-serif;
}

#coming-soon {
  color: #B39055;
}

#first-listeners-club {
  color: #B39055;
}

#nav-vip {
  font-family: 'Afacad', sans-serif;
  color: #B39055;
  font-weight: 650;
}

#vip-button {
  font-family: 'Afacad', sans-serif;
  font-weight: 450;
}

#nav-vip:hover {
  color: #D2CAAF;
}

/* #dice-event-list-widget {
  width: '100%';
  min-height: '1000px';
  overflow: 'scroll'
} */

#event-list-iframe {
  width: 100%;
  min-height: 825px;
  overflow-y: auto;
}

#event-list-iframe, #event-list-iframe * {
  font-family: 'Afacad', sans-serif !important;
}

#hr-theme-slash-2 {
  display: flex;
}

#hr-line {
  width: 100%;
  position: relative;
  margin: 15px;
  border-bottom: 3px solid #D2CAAF;
}
  
#hr-text {
  font-family: 'Afacad', sans-serif;
  margin-left: 10px;
  margin-right: 10px; 
  text-align: center;
  position: relative;
  align-content: center;
  top: 3px;
}

#hr-line-2 {
  width: 100%;
  position: relative;
  margin: 15px;
  border-bottom: 3px solid #D2CAAF;
}

/* img {
  height: auto;
  max-width: 20%;
} */

/* #logo {
  width: 40px;
  height: auto;
} */